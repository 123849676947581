var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "links" }, [
    _c(
      "div",
      { staticClass: "panel-gray" },
      [
        _c(
          "b-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "b-row",
              { staticClass: "linkList" },
              [
                _c("b-col", { attrs: { sm: "12", md: "6", lg: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "linkBlock" },
                    [
                      _c(
                        "p",
                        { staticClass: "linkTitle" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "assistenza" } } },
                            [_vm._v("Assistenza")]
                          ),
                        ],
                        1
                      ),
                      _c("router-link", { attrs: { to: { name: "faq" } } }, [
                        _vm._v("Domande frequenti"),
                      ]),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "assistenza-viaggi-sicuro" } } },
                        [_vm._v("La sicurezza di viaggiare con noi")]
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "assistenza-tecnologia" } } },
                        [_vm._v("La nostra tecnologia per te")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "assistenza-acquista-con-il-welfare-aziendale",
                            },
                          },
                        },
                        [_vm._v("Acquista con welfare aziendale")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "assistenza-acquista-un-voucher-salabam",
                            },
                          },
                        },
                        [_vm._v("Acquista un Salabam")]
                      ),
                      this.$config.guiSettings.giftCardsEnabled
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "assistenza-regala-un-salabam" },
                              },
                            },
                            [_vm._v("Regala un Salabam")]
                          )
                        : _vm._e(),
                      !this.mixinGetIntegrationInfo().sso &&
                      this.$config.guiSettings.giftCardsEnabled
                        ? _c(
                            "router-link",
                            { attrs: { to: { name: "gift-cards" } } },
                            [_vm._v("Scopri la gift card")]
                          )
                        : _vm._e(),
                      _vm.isPromo
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "assistenza-promozione-in-corso" },
                              },
                            },
                            [
                              _c("CopyScadenza", {
                                attrs: {
                                  default: "{value} mesi per viaggiare",
                                  promoMonths:
                                    "Promo {value} mesi per viaggiare",
                                  promoFixed: "Promo viaggia entro il {value}",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "assistenza-prenota-un-viaggio" },
                          },
                        },
                        [_vm._v("Usa un Salabam")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "assistenza-prenota-un-viaggio" },
                          },
                        },
                        [_vm._v("Prenota un viaggio")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "assistenza-richiedi-un-preventivo" },
                          },
                        },
                        [_vm._v("Richiedi un preventivo")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "assistenza-segnala-un-problema" },
                          },
                        },
                        [_vm._v("Segnala un problema")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "assistenza-contatti-contattaci" },
                          },
                        },
                        [_vm._v("Contattaci")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("b-col", { attrs: { sm: "12", md: "6", lg: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "linkBlock" },
                    [
                      _c(
                        "p",
                        { staticClass: "linkTitle" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "chi-siamo" } } },
                            [_vm._v("Chi siamo")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "chi-siamo-per-i-viaggiatori" },
                          },
                        },
                        [_vm._v("Per gli utenti")]
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "chi-siamo-per-le-aziende" } } },
                        [_vm._v("Per le aziende")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "chi-siamo-per-gli-hotel-manager" },
                          },
                        },
                        [_vm._v("Per gli hotel manager")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "chi-siamo-per-gli-hotel-manager" },
                          },
                        },
                        [_vm._v("Per i gestori di B&B e appartamenti")]
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "chi-siamo-per-le-ota" } } },
                        [_vm._v("Per le OTA")]
                      ),
                      _c("router-link", { attrs: { to: { name: "blog" } } }, [
                        _vm._v("Blog"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("b-col", { attrs: { sm: "12", md: "6", lg: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "linkBlock" },
                    [
                      _c(
                        "p",
                        { staticClass: "linkTitle" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "servizi-business" } } },
                            [_vm._v("Salabam Solutions")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "servizi-business-soluzioni-per-il-marketing",
                            },
                          },
                        },
                        [_vm._v("Per incentive, marketing and loyality")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "servizi-business-per-il-welfare-aziendale",
                            },
                          },
                        },
                        [_vm._v("Per il welfare aziendale")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "servizi-business-soluzioni-per-regali-e-pubbliche-relazioni",
                            },
                          },
                        },
                        [_vm._v("Per regali e PR")]
                      ),
                      _c(
                        "a",
                        { attrs: { href: "https://business.vivamod.com" } },
                        [_vm._v("Vivamod | modern travel")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("b-col", { attrs: { sm: "12", md: "6", lg: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "linkBlock" },
                    [
                      _c(
                        "p",
                        { staticClass: "linkTitle" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "terms" } } },
                            [_vm._v("Legal")]
                          ),
                        ],
                        1
                      ),
                      _c("router-link", { attrs: { to: { name: "terms" } } }, [
                        _vm._v("Termini e condizioni"),
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.site.urlPrivacy,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Privacy policy")]
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.site.urlCookiePolicy,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Cookie policy")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "assistenza-contatti-contattaci" },
                          },
                        },
                        [_vm._v("Contatti")]
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://assets.salabam.com/docs/agevolazioni-statali-salabam.pdf",
                            target: "_blank",
                          },
                        },
                        [_vm._v("Agevolazioni statali")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "footer-bottom" }, [
            _c("a", { attrs: { href: "#" } }, [
              _vm._v("© SALABAM SRL - P.IVA: 04238550406"),
            ]),
            _c(
              "a",
              { attrs: { href: "http://www.taiview.com", target: "_blank" } },
              [_vm._v("Ui & Ux Design: taiview.com")]
            ),
            _c("div", [
              _vm.site.facebook != ""
                ? _c(
                    "a",
                    { attrs: { href: _vm.site.facebook, target: "_blank" } },
                    [_c("Facebook")],
                    1
                  )
                : _vm._e(),
              _vm.site.linkedin != ""
                ? _c(
                    "a",
                    { attrs: { href: _vm.site.linkedin, target: "_blank" } },
                    [_c("Linkedin")],
                    1
                  )
                : _vm._e(),
              _vm.site.instagram != ""
                ? _c(
                    "a",
                    { attrs: { href: _vm.site.instagram, target: "_blank" } },
                    [_c("Instagram")],
                    1
                  )
                : _vm._e(),
              _c("a", { attrs: { href: "/blog" } }, [_c("SvgBlog")], 1),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }