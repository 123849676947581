<template>
    <div class="links">
        <div class="panel-gray">
            <b-container fluid>
                <b-row class="linkList">
                    <b-col sm="12" md="6" lg="3">
                      <div class="linkBlock">
                        <p class="linkTitle">
                          <router-link :to="{name: 'assistenza'}">Assistenza</router-link>
                        </p>
                        <router-link :to="{name: 'faq'}">Domande frequenti</router-link>
                        <router-link :to="{name: 'assistenza-viaggi-sicuro'}">La sicurezza di viaggiare con noi</router-link>
                        <!--<router-link :to="{name: 'assistenza-covid19'}">Covid-19</router-link>-->
                        <router-link :to="{name: 'assistenza-tecnologia'}">La nostra tecnologia per te</router-link>
                        <router-link :to="{name: 'assistenza-acquista-con-il-welfare-aziendale'}">Acquista con welfare aziendale</router-link>
                        <router-link :to="{name: 'assistenza-acquista-un-voucher-salabam'}">Acquista un Salabam</router-link>
                        <router-link :to="{name: 'assistenza-regala-un-salabam'}" v-if="this.$config.guiSettings.giftCardsEnabled">Regala un Salabam</router-link>
                        <router-link v-if="!this.mixinGetIntegrationInfo().sso && this.$config.guiSettings.giftCardsEnabled" :to="{name: 'gift-cards'}">Scopri la gift card</router-link>

                        <router-link v-if="isPromo" :to="{name: 'assistenza-promozione-in-corso'}">
                          <CopyScadenza
                            default="{value} mesi per viaggiare"
                            promoMonths="Promo {value} mesi per viaggiare"
                            promoFixed="Promo viaggia entro il {value}"
                          />
                        </router-link>

                        <router-link :to="{name: 'assistenza-prenota-un-viaggio'}">Usa un Salabam</router-link>
                        <router-link :to="{name: 'assistenza-prenota-un-viaggio'}">Prenota un viaggio</router-link>
                        <router-link :to="{name: 'assistenza-richiedi-un-preventivo'}">Richiedi un preventivo</router-link>
                        <router-link :to="{name: 'assistenza-segnala-un-problema'}">Segnala un problema</router-link>
                        <router-link :to="{name: 'assistenza-contatti-contattaci'}">Contattaci</router-link>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                      <div class="linkBlock">
                        <p class="linkTitle">
                          <router-link :to="{name: 'chi-siamo'}">Chi siamo</router-link>
                        </p>
                        <router-link :to="{name: 'chi-siamo-per-i-viaggiatori'}">Per gli utenti</router-link>
                        <router-link :to="{name: 'chi-siamo-per-le-aziende'}">Per le aziende</router-link>
                        <router-link :to="{name: 'chi-siamo-per-gli-hotel-manager'}">Per gli hotel manager</router-link>
                        <router-link :to="{name: 'chi-siamo-per-gli-hotel-manager'}">Per i gestori di B&B e appartamenti</router-link>
                        <router-link :to="{name: 'chi-siamo-per-le-ota'}">Per le OTA</router-link>
                        <router-link :to="{name: 'blog'}">Blog</router-link>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                      <div class="linkBlock">
                        <p class="linkTitle">
                          <router-link :to="{name: 'servizi-business'}">Salabam Solutions</router-link>
                        </p>
                        <router-link :to="{name: 'servizi-business-soluzioni-per-il-marketing'}">Per incentive, marketing and loyality</router-link>
                        <router-link :to="{name: 'servizi-business-per-il-welfare-aziendale'}">Per il welfare aziendale</router-link>
                        <router-link :to="{name: 'servizi-business-soluzioni-per-regali-e-pubbliche-relazioni'}">Per regali e PR</router-link>
                        <!--<router-link :to="{name: 'servizi-business-vivamod-modern-travel'}">Vivamod | modern travel</router-link>-->
                        <a href="https://business.vivamod.com">Vivamod | modern travel</a>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                      <div class="linkBlock">
                        <p class="linkTitle">
                          <router-link :to="{name: 'terms'}">Legal</router-link>
                        </p>
                        <router-link :to="{name: 'terms'}">Termini e condizioni</router-link>
                        <a :href="site.urlPrivacy" target="_blank">Privacy policy</a>
                        <a :href="site.urlCookiePolicy" target="_blank">Cookie policy</a>
                        <!--<router-link :to="{name: 'homepage'}">Corporate</router-link>-->
                        <router-link :to="{name: 'assistenza-contatti-contattaci'}">Contatti</router-link>
                        <a href="https://assets.salabam.com/docs/agevolazioni-statali-salabam.pdf" target="_blank">Agevolazioni statali</a>
                      </div>
                    </b-col>
                </b-row>
            </b-container>
            
            <div class="info">
                <div class="footer-bottom">
                <a href="#">© SALABAM SRL - P.IVA: 04238550406</a>
                <a href="http://www.taiview.com" target="_blank">Ui & Ux Design: taiview.com</a>
                <div>
                    <a v-if="site.facebook != ''" :href="site.facebook" target="_blank"><Facebook /></a>
                    <a v-if="site.linkedin != ''" :href="site.linkedin" target="_blank"><Linkedin /></a>
                    <a v-if="site.instagram != ''" :href="site.instagram" target="_blank"><Instagram /></a>
                    <a href="/blog"><SvgBlog /></a>
                </div>
                </div>
            </div>    

        </div>
    </div>    
</template>

<script>
import Vue from 'vue'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'
import Facebook from '../../svg/facebook'
import Instagram from '../../svg/instagram'
import Linkedin from '../../svg/linkedin'
import SvgBlog from '@/components/svg/blog'

export default {
    components:
    {
        CopyScadenza,
        Facebook,
        Instagram,
        Linkedin,
        SvgBlog,
    },
    data(){
      return {
        isPromo: Vue.prototype.$config.guiSettings.vouchersValidity.isPromo,
        site: Vue.prototype.$config.site,
      }
    }
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/mixins";
  @import './../../../scss/_custom.scss';

  .links
  {
    .col-sm-12
    {
      display:flex;
      text-align: center;
      justify-content: center;

      @include media-breakpoint-up(lg){
        text-align: left;
      }
    }
    .linkTitle, .linkTitle a
    {
        color:$black;
        font-size: 16px!important;
        font-weight: 600;
        margin-bottom:1rem!important;
    }
    .linkList a
    {
        color:$black;
        font-size: 13px;
        font-weight: 600;
        display:block;
        margin-bottom:0.5rem;
    }
  }

  .info
  {
    text-align:center;
    //background:$white;
    padding-top:2rem;
    //padding-bottom:2rem;
  }

  .footer-bottom
  {
    display:flex;
    flex-direction: column;
    font-size:14px;
    & > *:not(:last-child)
    {
      margin-bottom:20px;
    }
    a
    {
      color:$black;
    }
    svg
    {
      margin:0 10px;
    }
  }

  @include media-breakpoint-up(lg)
  {
    .footer-bottom
    {
      flex-direction:row;
      justify-content:space-between;
      & > *:not(:last-child)
      {
        margin-bottom:0;
      }
      & > *
      {
        flex:33%;
        width:33%;
        text-align:center;
      }
    }
  }
</style>