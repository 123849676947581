var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navigation",
      on: {
        click: function ($event) {
          return _vm.closeMenu()
        },
      },
    },
    [
      _c("ul", { staticClass: "links" }, [
        _vm._m(0),
        _vm.mixinGetIntegrationInfo().mode == "boxset"
          ? _c(
              "li",
              [
                _c("router-link", { attrs: { to: { name: "shop" } } }, [
                  _vm._v("Shop"),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "blog" } } }, [
              _vm._v("Blog"),
            ]),
          ],
          1
        ),
        this.$config.guiSettings.giftCardsEnabled
          ? _c("li", { staticClass: "menuItemWithIcon" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.mixinGoTo("gift-cards", {})
                    },
                  },
                },
                [_c("SvgGift"), _vm._v("Regala\n            ")],
                1
              ),
            ])
          : _vm._e(),
        this.mixinGetIntegrationInfo().mode != "booking"
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "login-salabam" } } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "white",
                        attrs: { size: "sm", variant: "primary" },
                      },
                      [_vm._v(_vm._s(_vm._f("uppercase")("ho un salabam")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.PUBLICPATH != "cancellara" &&
        this.mixinGetIntegrationInfo().sso &&
        !this.mixinGetIntegrationInfo().integration == "jointly"
          ? _c("li", [
              _c(
                "a",
                {
                  attrs: { href: _vm.legacyUrl + "link/sito-classico" },
                  on: {
                    click: function ($event) {
                      return _vm.trackBackToLegacy()
                    },
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "white",
                      attrs: { size: "sm", variant: "secondary" },
                    },
                    [_vm._v(_vm._s(_vm._f("uppercase")("sito classico")))]
                  ),
                ],
                1
              ),
            ])
          : _vm.PUBLICPATH != "cancellara" &&
            (this.$route.name == "login-salabam" ||
              this.$route.name == "login-salabam-fields") &&
            !this.mixinGetIntegrationInfo().integration == "jointly"
          ? _c("li", [
              _c(
                "a",
                {
                  attrs: { href: _vm.legacyUrl + "link/login-classico" },
                  on: {
                    click: function ($event) {
                      return _vm.trackBackToLegacy()
                    },
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "white",
                      attrs: { size: "sm", variant: "secondary" },
                    },
                    [_vm._v(_vm._s(_vm._f("uppercase")("sito classico")))]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: { href: "http://www.salabamsolutions.com", target: "_blank" },
        },
        [_vm._v("Salabam Solutions")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }