<template>
    <div class="publicPage" id="giftCards">
        <div class="heroContainer">
            <img src="@/assets/images/giftcard-header.jpg">
            <!--<img src="@/assets/images/giftcard-header-natale.jpg">-->
            <div class="captions">
                <h2 class="secondaryCaption">Una bellissima card racchiusa in un cofanetto regalo ecologico e di design. Ma è sempre Salabam: semplice e sicuro. Online!</h2>
                <h3 class="primaryCaption">
                    <span>Nessun supplemento.<br>SPEDIZIONE GRATUITA!</span>
                </h3>
            </div>
        </div>
        <div id="page">
            <div id="info">
                <b-container>
                    
                    <br><br><br>
                    
                    <section>

                        <h4 class="text-center" style="font-weight: normal">
                            Ecco il nostro cofanetto regalo*
                        </h4>

                        <h3 class="text-center">
                          Semplice e potente come il Salabam online
                        </h3>

                    </section>
                    
                    <br><br><br>

                    <section>

                        <Quote :text="'Regalare un voucher per viaggiare è l\'augurio più bello che tu possa fare, in ogni occasione!'" />

                        <br><br><br>



                            <h3 class="text-center">
                                <CopyScadenza
                                    default="{value} mesi per viaggiare"
                                    promoMonths="{value} mesi per viaggiare"
                                    promoFixed="Viaggia entro il {value}"
                                />
                            </h3>

                          <br><br>

                          <p>Tu regali e l'altro sceglie dove e quando viaggiare.</p>

                          <p>Un servizio che rivoluziona il concetto di cofanetto regalo perché nasce online e le disponibilità sono davvero prenotabili con pochi click, senza attese, senza richieste di conferma e problemi di disponibilità.</p>



                    </section>


                    <br><br><br>

                    <section>
                        <h3>Come funziona?</h3>
                        
                        <br/>

                        <div class="checklist">
                            <ul>
                                <li>
                                    <span class="checklist_index">1</span>
                                    <div class="checklist_item">
                                        <span>Scegli il voucher che vuoi regalare sullo <a href="/shop">shop</a> oppure <a href="/ricerca">facendo ricerche</a> per vedere le disponibilità</span>
                                    </div>
                                </li>
                                <li>
                                    <span class="checklist_index">2</span>
                                    <div class="checklist_item">
                                        <span>Nella pagina di checkout seleziona l\'opzione "&Egrave; un regalo" e scegli se lo vuoi spedire per mail o vuoi inviare la scatolina regalo e gift card per corriere.</span>
                                    </div>
                                </li>
                                <li>
                                    <span class="checklist_index">3</span>
                                    <div class="checklist_item">
                                        <CopyScadenza
                                            default="Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà ben {value} mesi per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo"
                                            promoMonths="Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà ben {value} mesi per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo"
                                            promoFixed="Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà tempo fino al {value} per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <br>

                        <div class="text-center">
                            <p>
                                <strong>
                                    E ricorda la promozione: la scatolina, la gift card e la spedizione sono gratis!.
                                </strong>
                            </p>

                            <br>               
                                         
                            <b-button class="ml-2 mr-2" v-b-modal.modalGiftCardsInfo>Vedi la gift card</b-button>

                            <!-- vado in homepage e non in shop, è un comportamento voluto -->
                            <b-button class="ml-2 mr-2" v-if="config.guiSettings.giftCardsEnabled" @click="mixinGoTo('homepage',{})">Vai allo shop</b-button>

                        </div>
                        <ModalGiftCard />
                        <br><br>
                        <p>* Attenzione: non disponibile per acquisti con welfare aziendale</p>
                    </section>
                    
                    

                    <section v-if="!config.guiSettings.giftCardsEnabled">
                        <br><br><br>
                        <FormGiftCard
                            :formTitle="'Resta aggiornato sul lancio che avverrà tra qualche giorno'"
                            :recipient="config.site.emailInfo"
                        />
                    </section>
                    
                    <br><br><br>                 

                    <section id="business">
                        <h3>Regalo aziendale?</h3>
                        
                        <br/>

                        <p class="mb-0">
                            La gift card Salabam è un'ottima idea per regali aziendali ai tuoi migliori clienti, partner e collaboratori. Una gift card Salabam
                        </p>

                        <b-row>
                            <b-col sm="12" md="6" class="text-center">
                                <div class="svgIcon">
                                    <SvgDiamond />
                                    <h6>Un regalo importante</h6>
                                </div>
                                <br>
                                <p>
                                    Regalare un viaggio che il destinatario può scegliere online è un'idea innovativa e originale.
                                    <br>
                                    E con Salabam hai un'ampia scelta, da 75€ fino a 3500€ per ogni esigenza: clienti importanti, partner, collaboratori.
                                </p>
                            </b-col>
                            <b-col sm="12" md="6" class="text-center">
                                <div class="svgIcon">
                                    <SvgLoader />
                                    <h6>Un regalo smart</h6>
                                </div>
                                <br>
                                <p>
                                    <CopyScadenza
                                        default="Salabam è smart perché tu regali un viaggio ma è il destinatario del regalo che avrà {value} mesi per scegliere quando e dove viaggiare."
                                        promoMonths="Salabam è smart perché tu regali un viaggio ma è il destinatario del regalo che avrà {value} mesi per scegliere quando e dove viaggiare."
                                        promoFixed="Salabam è smart perché tu regali un viaggio ma è il destinatario del regalo che avrà tempo fino al {value} per scegliere quando e dove viaggiare."
                                    />                                    
                                    <br>
                                    &Egrave; smart perché regali un box di cartoncino ecologico e una card di design da collezionare.
                                </p>
                            </b-col>
                            <b-col sm="12" md="6" class="text-center">
                                <div class="svgIcon">
                                    <SvgPacco />
                                    <h6>Un cofanetto 2.0</h6>
                                </div>
                                <br>
                                <p>
                                    Salabam non è il solito cofanetto regalo: lo prenoti online, su disponibilità aggiornate in tempo reale ed a conferma immediata.
                                </p>
                            </b-col>
                            <b-col sm="12" md="6" class="text-center">
                                <div class="svgIcon">
                                    <SvgRefund />
                                    <h6>Un regalo sicuro</h6>
                                </div>
                                <br>
                                <p>
                                    Regali una gift card ma hai tutto il valore di Salabam in fatto di sicurezza che ha reso Salabam un servizio immancabile nei piani welfare aziendale delle più innovative e prestigiose aziende italiane.
                                </p>
                            </b-col>

                            <b-col sm="12" md="6" class="text-center">
                              <div class="svgIcon">
                                <SvgBranding />
                                <h6>Branding</h6>
                              </div>
                              <br>
                              <p>
                                Ti proponiamo diverse soluzioni di brandizzazione della scatolina, della card e del sito web.
                              </p>
                              <br>
                              <p>
                                Per regali e promozioni totalmente in linea con la tua immagine coordinata aziendale.
                              </p>
                            </b-col>

                            <b-col sm="12" md="6" class="text-center">
                              <div class="svgIcon">
                                <SvgIntegrazione />
                                <h6>Integrazione</h6>
                              </div>
                              <br>
                              <p>
                                Le nostre soluzioni business sono integrabili con le tue piattaforme IT: siti web, intranet, pagine promozionali e web app.
                              </p>
                            </b-col>

                        </b-row>

                    </section>

                    <br><br>

                    <section class="text-center">
                      <b-button variant="secondary">
                        <router-link :to="{name: 'servizi-business'}" style="color:white!important">Scopri tutte le nostre soluzioni business</router-link>
                      </b-button>
                    </section>


                    <br><br>

                    <section>
                        <div class="panel panel-dark-blue">
                            <div class="panel-inner text-center">
                                <strong>
                                    Contattaci per un preventivo personalizzato all'indirizzo: <a class="primary" :href="'mailto:'+config.site.emailBusiness">{{config.site.emailBusiness}}</a>
                                </strong>
                            </div>
                        </div>
                    </section>

                    <br><br><br>

                    <section>

                        <h3>La sicurezza Salabam</h3>

                        <br/>

                        <p>
                            Che sia un regalo per un tuo caro o per un cliente della tua azienda, la sicurezza delle persone per te importanti è la nostra priorità.
                        </p>

                        <br/><br/>

                        <b-row class="nogutter">
                            <b-col sm="12" md="6">
                                <img src="@/assets/images/immagine-pagina-prodotto.jpg">
                            </b-col>
                            <b-col sm="12" md="6" class="hasBackground block">
                                <p>
                                    La nostra assistenza: valida e multicanale
                                </p>
                                <div>
                                    <ul>
                                        <!--<li>
                                            <CopyScadenza
                                                default="{value} mesi di validità"
                                                promoMonths="{value} mesi di validità"
                                                promoFixed="Validità fino al {value}"
                                            />
                                        </li>-->
                                        <li>
                                            possibilità di relazionarti in maniera efficace con persone, non con chatbot o algoritmi
                                        </li>
                                        <li>
                                            chat attiva, assistenza telefonica, supporto Whatsapp e via email
                                        </li>

                                    </ul>
                                </div>
                            </b-col>
                        </b-row>
                        
                    </section>
                    
                </b-container>

                    <br><br><br><br>

                <b-container fluid>

                    <section>
                        <ValuePropositions />
                    </section>

                </b-container>

                    <br><br><br>

                <b-container>

                    <section>
                        <div class="panel panel-dark-blue">
                            <div class="panel-inner text-center">
                                <strong>
                                    Contattaci per un preventivo personalizzato all'indirizzo: <a class="primary" :href="'mailto:'+config.site.emailBusiness">{{config.site.emailBusiness}}</a>
                                </strong>
                            </div>
                        </div>
                    </section>

                </b-container>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Quote from '../../blocks/common/quote'
//import Checklist from '../../blocks/common/checklist'
import FormGiftCard from '../../blocks/common/form-gift-card'
import ModalGiftCard from '@/components/blocks/common/modal-gift-card'
import ValuePropositions from '@/components/blocks/footer/value-propositions'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'
import SvgDiamond from '@/components/svg/diamond'
import SvgLoader from '@/components/svg/loader'
import SvgPacco from '@/components/svg/pacco'
import SvgRefund from '@/components/svg/refund'
import SvgBranding from '@/components/svg/branding'
import SvgIntegrazione from '@/components/svg/integrazione'

export default {
    name: 'gift-cards',
    metaInfo: {
      title: 'Gift card regalo',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Salabam non è solo digitale. Scopri la scatola regalo con la sua gift card da collezione per Natale' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Salabam non è solo digitale. Scopri la scatola regalo con la sua gift card da collezione per Natale' },
      ],
    },
    components:
    {
        Quote,
        //Checklist,
        FormGiftCard,
        ModalGiftCard,
        ValuePropositions,
        CopyScadenza,
        SvgDiamond, SvgLoader, SvgPacco, SvgRefund, SvgBranding, SvgIntegrazione,
    },
    mounted(){

      if (!this.$config.guiSettings.giftCardsEnabled) this.$router.push({name: '404'});

    },
    data(){
    return {
        config: Vue.prototype.$config,
        /*
        checklistItems_comeFunziona: {
                0: 'Scegli il voucher che vuoi regalare sullo <a href="/shop">shop</a> oppure <a href="/ricerca">facendo ricerche</a> per vedere le disponibilità',
                1: 'Nella pagina di checkout seleziona l\'opzione "&Egrave; un regalo" e scegli se lo vuoi spedire per mail o vuoi inviare la scatolina regalo e gift card per corriere.',
                2: 'Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà ben 16 mesi per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo',
            }*/
        }
    }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../../../scss/_custom.scss';

#giftCards
{
    .heroContainer
    {
        position:relative;
        .captions
        {
            @include media-breakpoint-down(xs)
            {
                display: none;
            }
            position:absolute;
            left:5%;
            right:5%;
            top:50%;
            transform:translateY(-50%);
        }
        .primaryCaption
        {
            color:$white;
            background:$primary;
            font-size:14px;
            padding:10px;
            font-weight: normal;
        }
        .secondaryCaption
        {
            width:100%;
            color:$white;
            background:rgba($dark-blue,0.85);
            font-size:14px;
            padding:10px;
            //font-weight: normal;
        }
        @include media-breakpoint-up(md)
        {
            .captions
            {
                left:40%;
                display:flex;
                flex-direction:column;
                align-items: flex-end;
                justify-content: center;
                text-align: center;
            }
            .primaryCaption
            {
                height:150px;
                width:150px;
                border-radius:50%;
                display:flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-top: -20px;
                transform:translateX(-25%);
            }
            .secondaryCaption
            {
                padding:20px 30px;
                text-align:left;
            }
        }
        @include media-breakpoint-up(lg)
        {
            .primaryCaption
            {
                font-size: 20px;
                height:200px;
                width:200px;
                margin-top: -20px;
                transform:translateX(-25%);
                span
                {
                    text-align: center;
                    display:block;
                    }                
            }
            .secondaryCaption
            {
                font-size: 16px;
                padding:30px 35px;
            }
        }
        @include media-breakpoint-up(xxl)
        {
            .captions
            {
                left:45%;
            }
            .primaryCaption
            {
                font-size: 30px;
                height:350px;
                width:350px;
                margin-top: -30px;
                transform:translateX(-45%);
                line-height: 140%;
                span
                {
                    max-width:200px;
                    margin:0 auto;
                    text-align: center;
                    display:block;
                }                
            }
            .secondaryCaption
            {
                font-size: 24px;
                padding:40px 45px;
                line-height: 140%;
            }
        }
    }

    .quote
    {
        max-width:700px;
        margin:auto;
        .content
        {
            text-align:left;
        }
    }

    .svgIcon
    {
        text-align:center;
        display:block;
        margin-top:3rem;
        svg
        {
            width:100px;
            max-width: 100%;
            max-height:75px;
            margin-bottom:2rem;
            path {
                fill: $primary;
            }
        }
    }
    .hasBackground.block
    {
        flex-direction: column;
        padding-top:16px !important;
        padding-bottom:16px !important;
        justify-content: center;
        ul
        {
            max-width:300px;
            margin:0 auto;
            margin-top:1rem;
        }
        li
        {
            list-style: disc;
        }
    }

}
</style>